import axios from 'axios';
import authHeader from './auth-header';
import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'dealer/';

class DealerService {

    getDealerData(dealerId, language) {
      return new Promise(( resolve, reject ) => {
          axios.get(API_URL + dealerId + '?language=' + language, { headers: authHeader() }).then((response) => {
            resolve(response)
          }).catch((err) => {
            reject(err)
          })
      })
    }

    getDealerPageData(dealerId, language) {
      return new Promise(( resolve, reject ) => {
        axios.get(API_URL + dealerId + '/pageData' + '?language=' + language, { headers: authHeader() }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }

    async checkDealerNameAlreadyExists(dealerId, name) {
      return new Promise(( resolve, reject ) => {
        axios.get(API_URL + dealerId + '/checkName?name=' + name, { headers: authHeader() }).then((response) => {
          console.log('checkDealerNameAlreadyExists', response.data)
          resolve(response.data);
        }).catch((err) => {
          reject(err)
        })
      })
    }

    async addMapKml(fileList) {
      try {
        let headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        let response = await axios.post(API_URL + 'addMapKml', fileList, { headers: headers });
        return { success: true, data: response.data } ;
      }
      catch (err) {
        return { success: false, data: parseError(err) };
      }
    }

    getMarkers(params) {
      let query = API_URL + 'markers?';
      for (let param in params) {
        if (Array.isArray(params[param])) query += `&${param}=` + params[param].join(`&${param}=`);
        else query += `&${param}=${params[param]}`;
      }
      query = query.replace('markers?&', 'markers?');
      return axios.get(query, { headers: authHeader() });
    }

    getOtherProjects(projectId, dealerId, language) {
      return new Promise(( resolve, reject ) => {
          axios.get(API_URL + 'otherProjects?projectId=' + projectId + '&dealerId=' + dealerId + '&language=' + language,
                    { headers: authHeader() }).then((response) => {
            resolve(response)
          }).catch((err) => {
            reject(err)
          })
      })
    }

    getMapProjectsDetails(projects, language, userId) {
      return new Promise(( resolve, reject ) => {

        let url = constants.API_URL + 'mapProjectsDetails?language=' + language + '&userId=' + userId;
        for (let project of projects) {
          url += '&id=';
          url += project.id;
        }
        axios.get(url, { headers: authHeader() }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
  }

  getDealerMaps(language) {
    return axios.get(constants.API_URL + 'dealerMaps?language=' + language, { headers: authHeader() });
  }

  calculateTorque(data) {
    return axios.post(API_URL + 'calculateTorque', data, { headers: authHeader() });
  }

  calculateCompression(data) {
    return axios.post(API_URL + 'calculateCompression', data, { headers: authHeader() });
  }
  
  calculateTension(data) {
    return axios.post(API_URL + 'calculateTension', data, { headers: authHeader() });
  }
}

export default new DealerService();