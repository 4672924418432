<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <Breadcrumbs />


    <br v-if="!editedDealer" />

    <v-card class="border10 mx-3 mt-3">
      <v-progress-linear v-if="!editedDealer" indeterminate></v-progress-linear>
      <v-skeleton-loader
        v-if="!editedDealer"
        type="table-heading, list-item-two-line, image, table-tfoot"
        class="ma-5"
      ></v-skeleton-loader>

      <v-container v-if="editedDealer" class="ma-0" style="width: 100%; max-width: none;">
        <h3 class="mb-5">
          {{ $t("users.dealer") + " # " + editedDealer.id }}
        </h3>
        <v-row>
          <v-col md="4" sm="6" cols="12">
            <v-text-field
              :label="$t('users.name')"
              dense
              maxlength="50"
              @change="onNameChange"
              v-model="editedDealer.name"
              :error-messages="dealerNameErrors"
              :disabled="!userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP)"
            ></v-text-field>
            <v-text-field
              :label="$t('user.number')"
              dense
              maxlength="50"
              @change="onNumberChange"
              v-model="editedDealer.number"
              :disabled="!userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP)"
            ></v-text-field>
            <br/>
            <div id="comm-info">
              <v-text-field
                :label="$t('user.phone_mobile')"
                dense
                maxlength="50"
                @change="onPhoneMobileChange"
                v-model="editedDealer.phoneMobile"
              ></v-text-field>
              <v-text-field
                :label="$t('user.phone_office')"
                dense
                maxlength="50"
                @change="onPhoneOfficeChange"
                v-model="editedDealer.phoneOffice"
              ></v-text-field>
              <v-text-field
                :label="$t('user.main_email')"
                dense
                :rules="emailRules"
                @change="onEmailMainChange"
                v-model="editedDealer.emailMain"
                maxlength="320"
              ></v-text-field>
              <v-text-field
                :label="$t('dealer.email_engineering_documents')"
                dense
                :rules="optionalEmailRules"
                @change="onEmailEngineeringDocumentsChange"
                v-model="editedDealer.emailEngineeringDocuments"
                maxlength="320"
              ></v-text-field>
              <v-select
                v-model="editedDealer.communicationLanguage"
                @change="onCommunicationLanguageChange"

                :label="$t('user.communication_language')"
                :items="languageList"

                dense
              />
              <v-autocomplete
                v-model="editedDealer.timeZoneId"
                @change="onTimeZoneChange"

                :label="$t('dealer.time_zone')"
                :items="timeZones"
                item-text="name"
                item-value="id"
                dense
              />
            </div>
          </v-col>

          <v-col md="4" sm="6" cols="12">
            <v-select
              dense
              :items="[
                { name: $t('user.unit_metric_meters'), id: 0 },
                { name: $t('user.unit_imperial_feets'), id: 1 },
              ]"
              :label="$t('user.global_unit_measure')"
              item-text="name"
              item-value="id"
              v-model="editedDealer.globalMeasureUnit"
              @change="globalMeasureUnitChanged()"
            ></v-select>

            <v-select
              dense
              :items="[
                { name: $t('user.unit_metric_millimeters'), id: 0 },
                { name: $t('user.unit_imperial_inches'), id: 1 },
              ]"
              :label="$t('user.impact_unit_measure')"
              item-text="name"
              item-value="id"
              v-model="editedDealer.impactMeasureUnit"
              @change="impactMeasureUnitChanged()"
            ></v-select>
            
 <br/>

            <v-select
              dense
              :items="engineeringDepartments"
              :label="$t('dealer.engineering_department')"
              item-text="name"
              :disabled="!userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP)"
              item-value="id"
              v-model="editedDealer.engineeringDepartmentId"
              @change="engineeringDepartmentChanged()"
            ></v-select>

 <br/>

            <div v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP)">
              <label
                style="font-size: 12px;display:block;"
                >{{ $t("dealer.territory_kml") }}</label>
              <div
                @dragover.prevent="dragOver"
                @dragleave.prevent="dragLeave"
                @drop.prevent="dropMapKml($event)"
              >
                <div class="request-container" v-if="!editedDealer.files?.kml?.url?.download">
                  <div class="requests-anchor">
                      <img class="required-img">
                  </div>
                  <div class="upload-btn-wrapper">
                    <v-btn
                      fab
                      color="#009f4d"
                      dark
                      v-on:click="$refs.mapKmlPath.click();"
                    >
                      <v-progress-circular v-if="isSavingMapKml" color="white" indeterminate></v-progress-circular>
                      <v-icon v-else>mdi-cloud-upload</v-icon>
                    </v-btn>
                    <input
                      style="display:none;"
                      type="file"
                      name="file"
                      ref="mapKmlPath"

                      v-on:change="handleMapUpload"
                      
                      accept=".kml"
                    />
                  </div>
                </div>

                <div class="request-container" v-else>
                  <a :href="editedDealer.files.kml.url.download" style="width:100%;" class="requests-anchor" @click.prevent="downloadFile(editedDealer.files.kml)">
                    <img src="/assets/icon/doc-file.svg" style="height:50px;">
                    <span v-if="editedDealer.files.kml.renaming" class="editingFile">
                      <v-text-field :id="editedDealer.files.kml.inputId" v-model="editedDealer.files.kml.filename" @blur="renameFile(editedDealer.files.kml, 'disable')" @keyup.native.enter="renameFile(editedDealer.files.kml, 'disable')" maxlength="200"></v-text-field>
                    </span>
                    <span v-else class="wrappedfile">{{ editedDealer.files.kml.filename }}</span>
                  </a>
                  <span v-if="!editedDealer.files.kml.renaming">
                    <v-icon v-on:click="confirmMapKmlDelete()" class="delete-photo-icon">mdi-close</v-icon>
                    <v-icon v-on:click="renameFile(editedDealer.files.kml, 'enable')" class="rename-file-icon" :title="$t('reportInfo.rename_file')">mdi-form-textbox</v-icon>
                  </span>
                </div>
              </div>
            </div>

            <br/>

            <v-subheader style="font-size: 0.75rem;margin-left:-15px;margin-top:-20px;">{{
                $t("dealer.dealer_group")
            }}</v-subheader>

            <div v-for="(group, index) in editedDealer.groups" :key="'B'+ index">
                <v-row>
                    <v-col cols="18" style="line-height:51px;padding-top:0px;padding-bottom:0px;">
                    {{ group.name }}
                    </v-col>
                    <v-col cols="1">
                    <v-icon v-on:click="removeGroup(index)">mdi-close</v-icon>
                    </v-col>
                </v-row>
            </div>
            
            <v-select
                dense
                :items="availableDealerGroups"
                :label="$t('dealer.add_group')"
                item-text="name"
                item-value="id"
                v-model="groupAddId"
                style="margin-top:10px;"
                @change="groupAdded()"
            ></v-select>
            <br/>

            <label for="standardSupportingPlates" style="display: block;font-size: 0.75rem;">{{ $t("dealer.supporting_plate") }}</label>
            <v-autocomplete
              id="standardSupportingPlates"
              dense
              :items="editedDealer.dealerSupportingPlates.filter((s) => s.id != -1)"
              :item-text="item => item.name + ' / ' + item.translatedName + (item.index>0?'':' - ' + (item.mobility == 1 ? $t('reportInfo.fix') : $t('reportInfo.adjustable')) + ' - ' + getFormattedForm(item.form))"
              item-value="id"
              return-object
              v-model="editedDealer.editSupportingPlates"
              multiple
              chips
              @change="supportingPlatesChanged()">
              <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                        <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                        v-if="index === 2"
                        class="grey--text text-caption"
                        >
                        (+{{ editedDealer.editSupportingPlates.length - 2 }} {{ editedDealer.editSupportingPlates.length - 2 > 1 ? $t("general.others") : $t("general.other") }})
                    </span>
                </template>
            </v-autocomplete>
            <br/>

            <label for="placeAutocompleteRestricted" style="display: block;font-size: 0.75rem;">{{ $t("dealer.placeAutocompleteRestricted") }} (Max 5)</label>

            <v-autocomplete
              chips
              dense
              multiple
              @change="onPlaceAutocompleteRestrictedChange"
              v-model="editedDealer.placeAutocompleteRestricted"
              :items="['BE', 'CA', 'CH', 'DE', 'ES', 'FR', 'GB', 'LU', 'PL', 'US']"
            ></v-autocomplete>
            <v-row>
              <v-col>
                <v-select
                  dense
                  :items="installerReportOptions"
                  :label="$t('dealer.installer_report_dropdown_label')"
                  item-text="value"
                  item-value="id"
                  v-model="editedDealer.installerViewReportOption"
                  @change="updateInstallerReportsOption"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="4" sm="12" cols="12">
            <label style="display:block;">{{ $t("general.addresses") }}</label>
            <div>
              <div
                v-for="(address, index) in editedDealer.addresses"
                v-bind:key="'A' + address.id"
                class="address-block alternate-odd"
                style="padding: 15px 20px;"
              >
                <v-icon
                  class="delete-address-btn"
                  @click="confirmDeleteAddress(index)"
                >
                  mdi-close
                </v-icon>

                <AddressField
                  :title="$t('users.address') + ' #' + (index + 1)"
                  :ref="'dealerAddressTypeRef' + index"
                  @saveAddress="(changes) => saveAddress(index, address.id, changes)"
                  :showAddressType="true"
                  :address="address"
                  :id="'A' + index"
                />
              </div>
            </div>
            <v-btn
              v-if="editedDealer.addresses.length < 2"
              tile
              color="#009f4d"
              class="white-text mt-2"
              @click="addAddress()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t("general.add") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <br />

    <v-card class="border10 mx-3">
      <v-progress-linear v-if="!editedDealer" indeterminate></v-progress-linear>
      <v-skeleton-loader
        v-if="!editedDealer"
        type="table-heading, list-item-two-line, image, table-tfoot"
        class="ma-5"
      ></v-skeleton-loader>

      <v-container v-if="editedDealer" class="ma-0" style="width: 100%; max-width: none;">
        <h3 class="mb-5">
          {{ $t("dealer.fieldReportSettings") }}
        </h3>
        <v-row>
          <v-col lg="3" sm="6" cols="12">
            <h4>{{ $t("reportInfo.client") }}</h4>
            <v-checkbox
              v-model="editedDealer.showCustomerTelephone"
              :label="$t('user.phone_mobile')"
              @change="fieldReportSettingsChanged('showCustomerTelephone')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showCustomerEmail"
              :label="$t('users.email')"
              @change="fieldReportSettingsChanged('showCustomerEmail')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showCustomerCategory"
              :label="$t('customer.category')"
              @change="fieldReportSettingsChanged('showCustomerCategory')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showContacts"
              :label="$t('reportInfo.site_contact')"
              @change="fieldReportSettingsChanged('showContacts')"
              dense
            ></v-checkbox>
            <br/>
            <div v-if="engineering_department.permitRequestInfo || editedDealer.showPermit">
              <h4>{{ $t("documentRequests.documentRequests") }}</h4>
              <v-checkbox
                v-model="editedDealer.showPermit"
                :label="$t('documentRequests.dr_permit')"
                @change="fieldReportSettingsChanged('showPermit')"
                dense
              ></v-checkbox>
            </div>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <h4>{{ $t("reportInfo.pileInformation") }}</h4>
            <v-checkbox
              v-model="editedDealer.showPileName"
              :label="$t('reportInfo.pilename')"
              @change="fieldReportSettingsChanged('showPileName')"
              dense
            ></v-checkbox>
            <v-row>
              <v-col class="pt-3 pb-0">
                <v-checkbox
                  v-model="editedDealer.showPileLength"
                  :label="$t('reportInfo.pileLength')"
                  @change="showPileLengthChanged();fieldReportSettingsChanged('showPileLength')"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col v-if="showPileLengthDefault" class="pt-3 pb-0">
                <v-select
                  dense
                  :items="pileLengths"
                  :label="$t('dealer.default')"
                  item-text="value"
                  item-value="id"
                  v-model="editedDealer.defaultPileLength_ft"
                  @change="defaultPileLengthChanged()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-checkbox
                  v-model="editedDealer.showPileFinish"
                  :label="$t('reportInfo.blackorgalvanized')"
                  @change="showPileFinishChanged();fieldReportSettingsChanged('showPileFinish')"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col v-if="showPileFinishDefault" class="pt-0">
                <v-select
                  dense
                  :items="[ { name: '' , id: null }, { name: $t('reportInfo.black'), id: 0 }, { name: $t('reportInfo.galvanized'), id: 1 } ]"
                  :label="$t('dealer.default')"
                  item-text="name"
                  item-value="id"
                  v-model="editedDealer.defaultSurfaceFinishEnum"
                  @change="defaultSurfaceFinishEnumChanged()"
                ></v-select>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="editedDealer.showPileConcreteBlock"
              :label="$t('reportInfo.concrete_block')"
              @change="fieldReportSettingsChanged('showPileConcreteBlock')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showInvoiceLength"
              :label="$t('reportInfo.invoiced_length')"
              @change="fieldReportSettingsChanged('showInvoiceLength')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showAnchoredBedrock"
              :label="$t('reportInfo.anchored')"
              @change="fieldReportSettingsChanged('showAnchoredBedrock')"
              dense
            ></v-checkbox>
            <v-row>
              <v-col class="pt-3 pb-0">
                <v-checkbox
                  v-model="editedDealer.showExtension"
                  :label="$t('reportInfo.extension')"
                  @change="showExtensionChanged();fieldReportSettingsChanged('showExtension')"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col v-if="showExtensionDefault" class="pt-3 pb-0">
                <v-select
                  dense
                  :items="pileExtensionLengths"
                  :label="$t('dealer.default')"
                  item-text="value"
                  item-value="id"
                  v-model="editedDealer.defaultExtensionLength_ft"
                  @change="defaultExtensionChanged()"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0">
                <v-checkbox
                  v-model="editedDealer.showHammer"
                  :label="$t('reportInfo.hammer')"
                  @change="showHammerChanged();fieldReportSettingsChanged('showHammer')"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col v-if="showHammerDefault" class="pt-0">
                <v-select
                  dense
                  :items="[ { name: '' , id: null }, { name: $t('reportInfo.mass8') , id: 0 }, { name: $t('reportInfo.mass16'), id: 1 }, { name: 'PJ1', id: 2 }, { name: 'PJR', id: 3 } ]"
                  :label="$t('dealer.default')"
                  item-text="name"
                  item-value="id"
                  v-model="editedDealer.defaultHammerEnum"
                  @change="defaultHammerEnumChanged()"
                ></v-select>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="editedDealer.showPileImpacts"
              :label="$t('reportInfo.impact')"
              @change="fieldReportSettingsChanged('showPileImpacts')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showCompression"
              :label="$t('reportInfo.compressioncapacity')"
              @change="fieldReportSettingsChanged('showCompression')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showTension"
              :label="$t('reportInfo.tensioncapacity')"
              @change="fieldReportSettingsChanged('showTension')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showTorque"
              :label="$t('reportInfo.torque')"
              @change="fieldReportSettingsChanged('showTorque')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showPileNotInstalled"
              :label="$t('reportInfo.not_installed')"
              @change="fieldReportSettingsChanged('showPileNotInstalled')"
              dense
            ></v-checkbox>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <h4>{{ $t("documentRequests.dr_fieldreports") }}</h4>
            <v-checkbox
              v-model="editedDealer.showProjectReferenceNumber"
              :label="$t('reportInfo.projectinfo')"
              @change="fieldReportSettingsChanged('showProjectReferenceNumber')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showProjectDimensions"
              :label="$t('dealer.showProjectDimensions')"
              @change="fieldReportSettingsChanged('showProjectDimensions')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showProjectTypeHeight"
              :label="$t('dealer.showProjectTypeHeight')"
              @change="fieldReportSettingsChanged('showProjectTypeHeight')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showSoilType"
              :label="$t('summary.soiltype')"
              @change="fieldReportSettingsChanged('showSoilType')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showTorqueReadings"
              :label="$t('reportInfo.testprofile')"
              @change="fieldReportSettingsChanged('showTorqueReadings')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showInstallers"
              :label="$t('reportInfo.siteinstaller')"
              @change="fieldReportSettingsChanged('showInstallers')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showCollaborators"
              :label="$t('reportInfo.collaborators')"
              @change="fieldReportSettingsChanged('showCollaborators')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showWelders"
              :label="$t('reportInfo.sitewelder')"
              @change="fieldReportSettingsChanged('showWelders')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showComments"
              :label="$t('reportInfo.comments')"
              @change="fieldReportSettingsChanged('showComments')"
              dense
            ></v-checkbox>
            <br />
            <h5>{{ $t('reportInfo.infoinstallation')}}</h5>
            <v-checkbox
              v-model="editedDealer.showInfoWelding"
              :label="$t('summary.welding')"
              @change="fieldReportSettingsChanged('showInfoWelding')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showInfoSelfTappingScrew"
              :label="$t('summary.selftapping')"
              @change="fieldReportSettingsChanged('showInfoSelfTappingScrew')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showInfoStickers"
              :label="$t('reportInfo.stickersposed')"
              @change="fieldReportSettingsChanged('showInfoStickers')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showInfoGreenSleeve"
              :label="$t('summary.greensleeve')"
              @change="fieldReportSettingsChanged('showInfoGreenSleeve')"
              dense
            ></v-checkbox>
            <br />
            <h5>Mobile</h5>
            <v-checkbox
              v-model="editedDealer.showFieldReportSummary"
              :label="$t('reportInfo.summary')"
              @change="fieldReportSettingsChanged('showFieldReportSummary')"
              dense
            ></v-checkbox>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <h4>{{ $t("dealer.pdfSettings") }}</h4>
            <v-checkbox
              v-model="editedDealer.showPdfTorque"
              :label="$t('reportInfo.torque')"
              @change="fieldReportSettingsChanged('showPdfTorque')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showPdfCompression"
              :label="$t('reportInfo.compressioncapacity')"
              @change="fieldReportSettingsChanged('showPdfCompression')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showPdfTension"
              :label="$t('reportInfo.tensioncapacity')"
              @change="fieldReportSettingsChanged('showPdfTension')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showPdfSoilType"
              :label="$t('summary.soiltype')"
              @change="fieldReportSettingsChanged('showPdfSoilType')"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="editedDealer.showPdfComments"
              :label="$t('reportInfo.comments')"
              @change="fieldReportSettingsChanged('showPdfComments')"
              dense
            ></v-checkbox>
            <v-textarea dense 
                        rows="4" 
                        auto-grow
                        :label="$t('dealer.information')"
                        :hint="$t('dealer.information_detail')"
                        v-model="editedDealer.information"
                        @change="informationChanged()">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <br />

    <v-card class="border10 mx-3">
      <v-progress-linear v-if="!editedDealer" indeterminate></v-progress-linear>
      <v-skeleton-loader
        v-if="!editedDealer"
        type="table-heading, list-item-two-line, image, table-tfoot"
        class="ma-5"
      ></v-skeleton-loader>

      <v-container v-if="editedDealer" class="ma-0" style="width: 100%; max-width: none;">
        <h3 class="mb-5">
          {{ $t("dealer.dealerMachines") }}
        </h3>
        <v-row>
          <v-col lg="4" sm="6" cols="12">
            <v-list dense>
              <v-subheader>{{ $t("dealer.dealerMachines") }}</v-subheader>
              <v-list-item
                v-for="(item, i) in editedDealer.dealer_machines.filter((pt) => pt.id != -1)"
                :class="{ 'active-dealer-machine': selectedDealerMachine && item.id == selectedDealerMachine.id }"
                :key="i"
                @click="setSelectedDealerMachine(item.id)"
                @contextmenu="openMachineMenu($event, item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name + ' - ' + item.serialNumber + (item.isActive?'':' - ' + $t('listMachines.disabled'))"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              @click="addNewDealerMachine()"
              class="ma-2 add-pt-btn"
              rounded
              color="primary"
              dark
              >{{ $t("dealer.add_dealer_machine") }}</v-btn>
          </v-col>

          <v-col v-if="selectedDealerMachine" lg="4" sm="6" cols="12">
            <div class="address-block alternate-odd">
              <v-row>
                <v-spacer/>
                  <v-icon
                    style="position:absolute;top:3px;right:0px;"
                    @click="confirmDealerMachineDelete"
                  >
                    mdi-close
                  </v-icon>
              </v-row>
              <v-text-field
                :label="$t('reportInfo.dealermachinename')"
                dense
                maxlength="45"
                @change="onDealerMachineNameChange"
                v-model="selectedDealerMachine.name"
                style="margin-top:45px;"
              ></v-text-field>

              <v-text-field
                :label="$t('reportInfo.dealermachinenumber')"
                dense
                maxlength="45"
                @change="onDealerMachineNumberChange"
                v-model="selectedDealerMachine.serialNumber"
              ></v-text-field>

              <v-select
                dense
                :items="machines"
                :label="$t('dealer.machines')"
                item-text="name"
                item-value="id"
                v-model="selectedDealerMachine.machineId"
                @change="onDealerMachineChange()"
              ></v-select>
              
              <v-checkbox
              dense
              :label="$t('users.active')"
              @change="onDealerMachineIsActiveChange()"
              v-model="selectedDealerMachine.isActive"

            ></v-checkbox>
            </div>

            <v-list dense>
              <v-subheader>{{ $t("dealer.dealerMachineMotors") }}</v-subheader>

              <v-list-item
                v-for="(item, i) in selectedDealerMachine.editMotors"
                :class="{ 'active-dealer-machine': selectedDealerMachineMotor && item.id == selectedDealerMachineMotor.id }"
                :key="i"
                @click="setSelectedDealerMachineMotor(item.id);recalculateSimulation();"
                @contextmenu="openMotorMenu($event, item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <span class="default-motor" v-if="selectedDealerMachine.defaultMachineMotorId == item.id">{{ $t("dealer.default") }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              @click="addNewDealerMachineMotor()"
              class="ma-2 add-pt-btn"
              rounded
              color="primary"
              dark
              >{{ $t("dealer.add_dealer_machine_motor") }}</v-btn>
          </v-col>

          <v-col v-if="selectedDealerMachineMotor" lg="4" sm="12" cols="12">

            <div class="address-block alternate-odd">
              <v-spacer/>
              <v-icon
                style="position:absolute;top:3px;right:0px;"
                @click="confirmDealerMachineMotorDelete"
              >
                mdi-close
              </v-icon>

              <label for="dealers" style="display: block">{{ $t("reportInfo.motor") }}</label>
              <v-select
                id="dealers"
                dense
                :items="machineAvailableMotors"
                item-text="name"
                item-value="motorId"
                return-object
                v-model="selectedDealerMachineMotor.motorId"
                @change="selectedMotorChanged()"
              ></v-select>

              <div v-if="selectedDealerMachineMotor.id > 0">
                <v-btn
                  @click="setDefaultMotor()"
                  class="ma-2 add-pt-btn"
                  rounded
                  color="primary"
                  >{{ selectedDealerMachine.defaultMachineMotorId == selectedDealerMachineMotor.id ? $t("dealer.unset_default") : $t("dealer.set_default") }}</v-btn>

                <v-dialog
                ref="lastCalibrationDateModal"
                :disabled="!userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                v-model="lastCalibrationDateModal"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="selectedDealerMachineMotor.lastCalibration"
                    :label="$t('reportInfo.dealermachineLastCalibration')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    style="margin-top: 25px"
                  />
                </template>
                <v-date-picker
                  :first-day-of-week="getComputedFirstDayOfWeek"
                  v-model="selectedDealerMachineMotor.lastCalibration"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="lastCalibrationDateModal = false; selectedDealerMachineMotor.lastCalibration = selectedDealerMachineMotor.initialLastCalibration;"
                    >{{ $t("confirm.cancel") }}</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      updateLastCalibrationDate(true);
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
                </v-dialog>

                <div v-if="engineering_department">
                  <v-text-field
                  :label="$t('reportInfo.factor1')"
                  dense
                  v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                  maxlength="12"
                  @change="onFactor1FormulaChanged"
                  style="margin-top:15px;"
                  v-model="selectedDealerMachineMotor.factor1"
                ></v-text-field>

                <v-text-field
                  :label="$t('reportInfo.factor2')"
                  dense
                  v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                  maxlength="12"
                  @change="onFactor2FormulaChanged"
                  v-model="selectedDealerMachineMotor.factor2"
                ></v-text-field>

                <v-text-field
                  :label="$t('reportInfo.factor3')"
                  dense
                  v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                  maxlength="12"
                  @change="onFactor3FormulaChanged"
                  v-model="selectedDealerMachineMotor.factor3"
                ></v-text-field>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="$t('reportInfo.dealerMachineMotorTorqueFormula')"
                      dense
                      v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                      maxlength="250"
                      @change="onDealerMachineMotorTorqueFormulaChanged"
                      style="margin-top:15px;"
                      v-model="selectedDealerMachineMotor.torqueFormula"
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <span v-html="$t('dealer.torque_formula_tip')"></span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="$t('reportInfo.dealerMachineMotorCompressionFormula')"
                      dense
                      v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)"
                      maxlength="250"
                      @change="onDealerMachineMotorCompressionFormulaChanged"
                      v-model="selectedDealerMachineMotor.compressionFormula"
                      :disabled="!selectedDealerMachineMotor.torqueFormula"
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <span v-html="$t('dealer.compression_formula_tip')"></span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="$t('reportInfo.dealerMachineMotorTensionFormula')"
                      dense
                      v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS) &&
                            engineering_department && engineering_department.capacityTensionCalculator"
                      maxlength="250"
                      @change="onDealerMachineMotorTensionFormulaChanged"
                      v-model="selectedDealerMachineMotor.tensionFormula"
                      :disabled="!selectedDealerMachineMotor.compressionFormula"
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <span v-html="$t('dealer.tension_formula_tip')"></span>
                </v-tooltip>

                 <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>{{ $t('dealer.calculation_simulator') }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <v-text-field
                              :label="$t('dealer.formula_test_pression')"
                              dense
                              type="number" @wheel="$event.target.blur()"
                              @change="recalculateSimulation"
                              v-model="testPressure"
                              min="0"
                              maxlength="5" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              :label="$t('dealer.formula_test_depth')"
                              dense
                              type="number" @wheel="$event.target.blur()"
                              @change="recalculateSimulation"
                              v-model="testDepth"
                              min="0"
                              maxlength="6" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                                v-model="testPileModel"
                                @change="recalculateSimulation"
                                :label="$t('reportInfo.pilemodel')"
                                :items="pileModels"
                                return-object
                                item-text="name"
                                dense
                                hide-details
                              />
                          </v-col>
                          <v-col>
                            <v-select
                                v-model="testPileHelix"
                                @change="recalculateSimulation"
                                :label="$t('general.helixModel')"
                                :items="helixModels"
                                return-object
                                item-text="name"
                                dense
                                hide-details
                              />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select dense
                              :items="[ 2, 2.5, 3 ]"
                              :label="$t('dealer.security_factor_compression')"
                              v-model="safetyFactorCompression"
                              @change="recalculateSimulation" ></v-select>
                          </v-col>

                          <v-col v-if="engineering_department && engineering_department.capacityTensionCalculator">
                            <v-select dense
                              :items="[ 2, 2.5, 3 ]"
                              :label="$t('dealer.security_factor_tension')"
                              v-model="safetyFactorTension"
                              @change="recalculateSimulation" ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span style="display:inline-block;font-size:smaller;" 
                              v-html="$t('dealer.torque') + ': ' + (testTorque ? testTorque : '') + '<br/>' + 
                                      $t('dealer.compression') + ': ' + (testCompression ? testCompression : '') + '<br/>' + 
                                      (engineering_department && engineering_department.capacityTensionCalculator ? ($t('dealer.tension') + ': ' + (testTension ? testTension : '')) : '')">
                            </span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <div v-else style="color:red;font-size:14px;">
                  {{ $t('dealer.engineering_department_required') }}
                </div>
              
                <label v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS) || (!userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS) && selectedDealerMachineMotor.correlationTable)"
                      style="font-size: 12px; margin: 10px 5px 0px 10px;display:block;">{{ $t("reportInfo.dealerMachineMotorCorrelationTable") }}</label>
            
                    <div
                      @dragover.prevent="dragOver"
                      @dragleave.prevent="dragLeave"
                      @drop.prevent="dropCorrelationTable($event)"
                    >
                      <div class="request-container" v-if="!selectedDealerMachineMotor.correlationTable && userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)">
                        <div class="requests-anchor">
                            <img class="required-img">
                            <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                        </div>
                        <div class="upload-btn-wrapper">
                          <v-btn
                            fab
                            color="#009f4d"
                            dark
                            v-on:click="$refs.correlationTable.click();"
                          >
                            <v-progress-circular v-if="isSavingCorrelationTable" color="white" indeterminate></v-progress-circular>
                            <v-icon v-else>mdi-cloud-upload</v-icon>
                          </v-btn>
                          <input
                            style="display:none;"
                            type="file"
                            name="file"
                            ref="correlationTable"

                            v-on:change="handleCorrelationTableUpload"
                            
                            accept="application/pdf"
                          />
                        </div>
                      </div>

                      <div class="request-container" v-if="selectedDealerMachineMotor.correlationTable">
                        <a :href="selectedDealerMachineMotor.correlationTable.url?.download" class="requests-anchor" style="display:inline-block;vertical-align:middle;width:100%;" @click.prevent="downloadFile(selectedDealerMachineMotor.correlationTable)">
                          <img v-if="selectedDealerMachineMotor.correlationTable.url?.thumbnail != null" :src="selectedDealerMachineMotor.correlationTable.url.thumbnail" style="height:50px;">
                          <img v-else src="/assets/icon/doc-file.svg" style="height:50px;">
                          <span v-if="selectedDealerMachineMotor.correlationTable.renaming" class="editingFile">
                            <v-text-field :id="selectedDealerMachineMotor.correlationTable.inputId" v-model="selectedDealerMachineMotor.correlationTable.filename" @blur="renameFile(selectedDealerMachineMotor.correlationTable, 'disable')" @keyup.native.enter="renameFile(selectedDealerMachineMotor.correlationTable, 'disable')" maxlength="200"></v-text-field>
                          </span>
                          <span v-else class="wrappedfile">{{ selectedDealerMachineMotor.correlationTable.filename }}</span>
                        </a>
                        <v-progress-circular style="display:inline-block;" v-if="isSendingCorrelationTableEmail" color="black" indeterminate></v-progress-circular>
                        <span v-if="!selectedDealerMachineMotor.correlationTable.renaming">
                          <v-icon v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS)" v-on:click="confirmCorrelationTableDelete()" class="delete-photo-icon">mdi-close</v-icon>
                          <v-icon v-on:click="renameFile(selectedDealerMachineMotor.correlationTable, 'enable')" class="rename-file-icon" :title="$t('reportInfo.rename_file')">mdi-form-textbox</v-icon>
                        </span>
                      </div>
                    </div>
                
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <br/>
    <ConfirmDialog
        v-if="confirmDialog"
        v-model="confirmDialog"

        :title="confirmDialogTitle"
        :description="confirmDialogBody"

        :cancelName="confirmYesNo ? $t('reportInfo.no') : $t('confirm.cancel')"
        :cancelFunction="() => (confirmDialog = false)"
        :actionName="confirmYesNo ? $t('reportInfo.yes') : $t('confirm.ok')"
        :actionFunction="confirmDialogYes"
    />
    <v-dialog v-model="viewPdf.show" width="97%" height="100%">
      <v-card>
        <v-card-actions>
          {{viewPdf.title || ''}}
          <v-spacer></v-spacer>
          <v-icon @click="() => { viewPdf.show = false; viewPdf.data = null;}">mdi-close</v-icon>
        </v-card-actions>
        <v-card-text>
          <vue-pdf-app style="height: 83vh; display: block;" :pdf="viewPdf.data" page-scale="page-fit" theme.sync="light" :fileName="viewPdf.title + '.pdf'" :config="{ toolbar: { toolbarViewerRight: {openFile: false, viewBookmark: false} }, secondaryToolbar: false }"></vue-pdf-app>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewPdf.show && viewPdf.data == null" class="downloadingPdf">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";

import ConfirmDialog from '@/components/dialogs/ConfirmDialog'
import AddressField from "@/components/reusableFields/AddressField";
import UserService from "@/services/user.service";
import DealerService from "@/services/dealer.service";
import FileService from "@/services/file.service";

import { getFirstDayOfWeek } from "@/utils/utils";
import { languageList } from "@/utils/languages";
import { getFormattedForm, translateFormula, getLanguage, parseError, formatDealerSupportingPlates, sortByProperties } from "@/utils/utils";
import { FUNCTIONS, UNIT_MEASURES, INSTALLER_REPORT_VIEW_OPTIONS } from "@/enums";
import { convertFeetsToFeetAndInches, convertFeetsToMeters } from '@/utils/conversion';

import axios from "axios";
import authHeader from "@/services/auth-header";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

const ConfirmAction = Object.freeze({ DeleteAddress: 1, DeleteDealerMachine: 2, DeleteDealerMachineMotor: 3, DeleteCorrelationTable: 4, SendCorrelationTableEmail: 5, DeleteMapKml: 6 });
const formulaParser = require('hot-formula-parser').Parser;

export default {
  components: {
    Breadcrumbs,
    ConfirmDialog,
    AddressField,
    VuePdfApp
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      userFunctions: (state) => state.userFunctions,
      countries: (state) => state.countries
    }),
    getComputedFirstDayOfWeek() {
      return getFirstDayOfWeek();
    },
    engineering_department() {
      let engineering_department = this.engineeringDepartments.find(ed => ed.id == this.editedDealer.engineeringDepartmentId);
      return engineering_department;
    },
    languageList: languageList,
    availableDealerGroups() {
        let scope = this
        let availableDealerGroups = [];

        for(var g = 0; g < this.dealerGroups.length; g++)
        {
          let dealerGroupIdx = this.editedDealer.groups.findIndex(
            (group) => group.id == scope.dealerGroups[g].id
          );

          if(dealerGroupIdx == -1){
            availableDealerGroups.push(this.dealerGroups[g]);
          }

        }

        return availableDealerGroups;
    },
    pileExtensionLengths() {
      let array = [ { id: null, value: '' } ];
      for (let ext of this.editedDealer.PILE_EXTENSION_FT_LENGTHS) {
        array.push({ id: ext, value: ext + '\'' });
      }
      return array;
    },
    pileLengths() {
      let array = [ { id: null, value: '' } ];
      for (let lengthFt of this.editedDealer.PILE_LENGTHS_FT) {
        array.push({ id: lengthFt, value: this.editedDealer.globalMeasureUnit == UNIT_MEASURES.METRIC ? 
                                            convertFeetsToMeters(lengthFt) + 'm' :
                                            convertFeetsToFeetAndInches(lengthFt) });
      }
      return array;
    }
  },
  data() {
    return {
      selectedDealerId: null, 
      editedDealer: null,
      // Fix pour Vuejs 2
      showPileLengthDefault:false,
      showPileFinishDefault:false,
      showHammerDefault:false,
      showExtensionDefault:false,
      dealers: null,
      groupAddId: null,
      machineAvailableMotors: [],
      selectedDealerMachineMotor: null,
      selectedDealerMachine: null,
      autocompleteList: [],
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      selectedAddressIdx: null,
      emailRules: [(v) => /.+@.+\..+/.test(v) || this.$t("customer.email_valid")],
      optionalEmailRules: [(v) => v == '' || /.+@.+\..+/.test(v) || this.$t("customer.email_valid")],
      x: 0,
      y: 0,
      lastCalibrationDateModal: false,
      isSavingCorrelationTable: false,
      isSendingCorrelationTableEmail: false,
      isFileValid: false,
      savingFile: false,
      dropped: 0,
      testPressure: 1000,
      testDepth: 1.22,
      testTorque: null,
      testCompression: null,
      testTension: null,
      testPileModel: null,
      testPileHelix: null,
      safetyFactorCompression: 2,
      safetyFactorTension: 2,
      correlationTableFile: null,
      dealerNameErrors: [],
      dealerGroups: [],
      engineeringDepartments: [],
      timeZones: [],
      machines: [],
      motors: [],
      pileModels: [],
      helixModels: [],
      FUNCTIONS: FUNCTIONS,
      isSavingMapKml: false,
      installerReportOptions: [],
      viewPdf: { show: false, title: null, data: null}
    };
  },
  mounted() {
    for (let key in INSTALLER_REPORT_VIEW_OPTIONS) {
      this.installerReportOptions.push({id: key, value: this.$t(`dealer.installer_report_${key}`)});
    }
    this.setBreadcrumbs([
      { text: this.$t("dealers.title"), href: "/dealers" },
      {
        text: this.$t("users.dealer") + " # " + this.$route.params.id,
        href: "/dealer/" + this.$route.params.id,
      },
    ]);

    if (this.editedDealer == null) {
      this.setEditedDealer(this.$route.params.id);
    }
    this.setSelectedDealerMachine(null);
    this.setSelectedDealerMachineMotor(null);
  },
  created() {
    console.log("Report created");
  },
  beforeDestroy() {
    console.log('Dealer unmounted')
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
      logout: 'logout'
    }),
    getFormattedForm: getFormattedForm,
    async saveDealerGroup(data) {
      UserService.saveDealerGroup(data);
    },
    setDefaultMotor() {

      let defaultMachineMotorId = this.selectedDealerMachine.defaultMachineMotorId == this.selectedDealerMachineMotor.id ? null : this.selectedDealerMachineMotor.id;

      let changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          defaultMachineMotorId: defaultMachineMotorId
        }
        this.selectedDealerMachine.defaultMachineMotorId = defaultMachineMotorId;
        this.saveDealerMachineMotor({ changes: changes });
    },
    removeGroup(index) {
      this.groupAddId = null;
        let removedGroup = this.editedDealer.groups[index];
        let changes = {
            changes: {
                dealerGroupId: removedGroup.id,
                dealerDeletedId: this.editedDealer.id
            },
        };

        this.editedDealer.groups.splice(index, 1);
        this.saveDealerGroup(changes);
    },
    groupAdded() {
        if (!this.groupAddId) {
            return;
        }
        console.log(this.groupAddId)
        let changes = {
            changes: {
                dealerGroupId: this.groupAddId,
                newDealerId: this.editedDealer.id
            },
        };
        let groupSelected = this.availableDealerGroups.find(d => d.id == this.groupAddId);
        this.editedDealer.groups.push(groupSelected);

        this.saveDealerGroup(changes);
    },
    capitalizeFirstLetter (str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    dropCorrelationTable(e) {
      let status = true;
      let files = Array.from(e.dataTransfer.files);
      if (e && files) {
        if (files[0].type.startsWith("application/pdf") === false) {status = false;}
        if (status == true) {
            this.isSavingCorrelationTable = true
            this.$refs.correlationTable = files[0];
            this.saveCorrelationTable(files[0]);
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Unsupported file type`;
        }
      }
      this.dropped = 0;
    },
    confirmDealerMachineDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t("confirm.head");
      this.confirmDialogBody = this.$t("confirm.delete_dealer_machine");
      this.confirmAction = ConfirmAction.DeleteDealerMachine;
      this.confirmYesNo = true;
    },
    confirmDealerMachineMotorDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t("confirm.head");
      this.confirmDialogBody = this.$t("confirm.delete_dealer_machine_motor");
      this.confirmAction = ConfirmAction.DeleteDealerMachineMotor;
      this.confirmYesNo = true;
    },
    confirmCorrelationTableDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteCorrelationTable;
      this.confirmYesNo = true;
    },
    confirmSendCorrelationTableEmail() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.correlation_table');
      this.confirmAction = ConfirmAction.SendCorrelationTableEmail;
      this.confirmYesNo = true;
    },
    async deleteCorrelationTable() {
      let resp = await FileService.deleteFile(this.selectedDealerMachineMotor.correlationTable);
      if (resp.success) this.selectedDealerMachineMotor.correlationTable = null;

      else this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      this.$forceUpdate();
    },
    async handleCorrelationTableUpload() {
      await this.saveCorrelationTable(this.$refs.correlationTable.files[0]);
      this.$refs.correlationTable.value = '';
    },
    async saveCorrelationTable(file) {
      this.isSavingFile = true;
      this.isSavingCorrelationTable = true;
      let resp = await FileService.addNewFile(file, 'dealer_machine_motors_correlation', this.selectedDealerMachineMotor.id);
      this.isSavingCorrelationTable = false;
      this.isSavingFile = false;
      console.log('add resp:', resp);
      if (resp.data?.alreadyExists === true) this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000}); // The upload button is only visible if there is no file yet, but may happen with a drag/drop
      else if (!resp.success) this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      else {
        this.selectedDealerMachineMotor.correlationTable = resp.data;
        this.$forceUpdate();
      }      
    },
    async onNameChange() {
      this.dealerNameErrors = [];

      let nameAlreadyExist = await DealerService.checkDealerNameAlreadyExists(this.editedDealer.id, this.editedDealer.name);

      if (nameAlreadyExist) {
        this.dealerNameErrors.push(this.$t("dealer.name_exist_already"));
      }
      else {
        this.editedDealer.name = this.capitalizeFirstLetter(this.editedDealer.name);
        this.editedDealer.changes.name = this.editedDealer.name;
        this.saveChanges();
      }
    },
    isActiveChanged() {
      console.log(this.editedDealer.isActive)
      this.editedDealer.changes.isActive = this.editedDealer.isActive;
      this.saveChanges();
    },
    onPlaceAutocompleteRestrictedChange() {
      if (this.editedDealer.placeAutocompleteRestricted.length > 5){
        this.editedDealer.placeAutocompleteRestricted.pop();
        this.openToast({ message: this.$t("dealer.maxXCountries", {x: 5}), duration: 3000, color: 'red'});
      }
      else{
        this.editedDealer.changes.placeAutocompleteRestricted = this.editedDealer.placeAutocompleteRestricted;
        this.saveChanges();
      }
    },
    onEmailMainChange() {
      this.editedDealer.changes.emailMain = this.editedDealer.emailMain;
      this.saveChanges();
    },
    onEmailEngineeringDocumentsChange() {
      this.editedDealer.changes.emailEngineeringDocuments = this.editedDealer.emailEngineeringDocuments;
      this.saveChanges();
    },
    onCommunicationLanguageChange() {
      this.editedDealer.changes.communicationLanguage = this.editedDealer.communicationLanguage;
      this.saveChanges();
    },
    onTimeZoneChange() {
      this.editedDealer.changes.timeZoneId = this.editedDealer.timeZoneId;
      this.saveChanges();
    },
    onNumberChange() {
      this.editedDealer.changes.number = this.editedDealer.number;
      this.saveChanges();
    },
    onEmailPersonalChange() {
      this.editedDealer.changes.emailPersonal = this.editedDealer.emailPersonal;
      this.saveChanges();
    },
    onEmailOtherChange() {
      this.editedDealer.changes.emailOther = this.editedDealer.emailOther;
      this.saveChanges();
    },
    onPhoneOfficeChange() {
      this.editedDealer.changes.phoneOffice = this.editedDealer.phoneOffice;
      this.saveChanges();
    },
    onPhoneMobileChange() {
      this.editedDealer.changes.phoneMobile = this.editedDealer.phoneMobile;
      this.saveChanges();
    },

    updateInstallerReportsOption() {
      this.editedDealer.changes.installerViewReportOption = this.editedDealer.installerViewReportOption;
      this.saveChanges();
    },
    
    fieldReportSettingsChanged(v) {
      this.editedDealer.changes[v] = this.editedDealer[v];
      this.saveChanges();
    },

    showExtensionChanged() {
      // Si on cache l'option des rallonges dans un rapport de chantier, effacer la valeur de rallonge par défaut
      if (!this.editedDealer.showExtension && this.editedDealer.defaultExtensionLength_ft != null) {
        this.editedDealer.defaultExtensionLength_ft = null;
        this.editedDealer.changes.defaultExtensionLength_ft = this.editedDealer.defaultExtensionLength_ft;
      }
      this.showExtensionDefault = this.editedDealer.showExtension;
    },

    showPileLengthChanged() {
      // Si on cache l'option des longuers de pieux dans un rapport de chantier, effacer la valeur de longueur de pieux par défaut
      if (!this.editedDealer.showPileLength && this.editedDealer.defaultPileLength_ft != null) {
        this.editedDealer.defaultPileLength_ft = null;
        this.editedDealer.changes.defaultPileLength_ft = this.editedDealer.defaultPileLength_ft;
      }
      this.showPileLengthDefault = this.editedDealer.showPileLength;
    },

    showPileFinishChanged() {
      if (!this.editedDealer.showPileFinish && this.editedDealer.defaultSurfaceFinishEnum != null) {
        this.editedDealer.defaultSurfaceFinishEnum = null;
        this.editedDealer.changes.defaultSurfaceFinishEnum = this.editedDealer.defaultSurfaceFinishEnum;
      }
      this.showPileFinishDefault = this.editedDealer.showPileFinish;
    },
    
    showHammerChanged() {
      if (!this.editedDealer.showHammer && this.editedDealer.defaultHammerEnum != null) {
        this.editedDealer.defaultHammerEnum = null;
        this.editedDealer.changes.defaultHammerEnum = this.editedDealer.defaultHammerEnum;
      }
      this.showHammerDefault = this.editedDealer.showHammer;
    },

    defaultHammerEnumChanged() {
      this.editedDealer.changes.defaultHammerEnum = this.editedDealer.defaultHammerEnum;
      this.saveChanges();
    },

    defaultSurfaceFinishEnumChanged() {
      this.editedDealer.changes.defaultSurfaceFinishEnum = this.editedDealer.defaultSurfaceFinishEnum;
      this.saveChanges();
    },

    defaultExtensionChanged() {
      this.editedDealer.changes.defaultExtensionLength_ft = this.editedDealer.defaultExtensionLength_ft;
      this.saveChanges();
    },

    defaultPileLengthChanged() {
      this.editedDealer.changes.defaultPileLength_ft = this.editedDealer.defaultPileLength_ft;
      this.saveChanges();
    },

    informationChanged() {
      this.editedDealer.changes.information = this.editedDealer.information;
      this.saveChanges();
    },

    globalMeasureUnitChanged() {
      this.editedDealer.changes.globalMeasureUnit = this.editedDealer.globalMeasureUnit;
      this.saveChanges();
    },

    impactMeasureUnitChanged() {
      this.editedDealer.changes.impactMeasureUnit = this.editedDealer.impactMeasureUnit;
      this.saveChanges();
    },

    engineeringDepartmentChanged() {
      this.editedDealer.changes.engineeringDepartmentId = this.editedDealer.engineeringDepartmentId;
      this.saveChanges();

      this.recalculateSimulation();
    },
    supportingPlatesChanged() {
      // Check for added supporting plates
      this.editedDealer.editSupportingPlates.forEach((esp) => {
        let supportingPlate = this.editedDealer.dealerSupportingPlates.find(sp => sp.id == esp.id);
        if (!supportingPlate.selected) {
          supportingPlate.selected = true;
          let changedSupportingPlate = { plateId: esp.id };
          this.editedDealer.changes.newSupportingPlate = changedSupportingPlate;
          this.addStandardSupportingPlate(esp);
        }
      });

      // Check for removed supporting plates
      this.editedDealer.dealerSupportingPlates.filter(dsp => dsp.selected).forEach((sp) => {
        let editSupportingPlate = this.editedDealer.editSupportingPlates.find(esp => sp.id == esp.id);
        if (!editSupportingPlate) {
          sp.selected = false;
          let changedSupportingPlate = { plateId: sp.id };
          this.editedDealer.changes.deleteSupportingPlate = changedSupportingPlate;
          this.removeStandardSupportingPlate(sp);
        }
      });
      this.saveChanges();
    },
    addAddress() {
      let dealerCountryId = null;
      let addressType = 0;
      console.log(this.editedDealer.changes.addedOrModifiedAddresses)
      if (this.editedDealer.addresses.length > 0) {
        dealerCountryId = this.editedDealer.addresses[0].countryId;
        if (this.editedDealer.addresses[0].addressType == 0) {
          addressType = 1;
        }
      }

      let address = { id: -1, countryId: dealerCountryId, addressType: addressType };

      if (dealerCountryId) {
        address.country = this.countries.find(
          (c) => c.id == dealerCountryId
        );
      }

      this.editedDealer.changes.addedOrModifiedAddresses.push(address);
      this.editedDealer.addresses.push(address);

      this.saveChanges();
    },
    getChangedAddress(addressId) {
      let addressChange = this.editedDealer.changes.addedOrModifiedAddresses.find(
        (a) => a.id == addressId
      );
      if (addressChange == null) {
        addressChange = { id: addressId };
        this.editedDealer.changes.addedOrModifiedAddresses.push(addressChange);
      }
      return addressChange;
    },
    saveAddress(index, id, addressChanges) {
      // If new address, add it
      if(!id || id == -1) {
        this.editedDealer.changes.addedOrModifiedAddresses.push(addressChanges);
      } 
      // If editing an address
      else {
        this.editedDealer.changes.addedOrModifiedAddresses.push({id: id, ...addressChanges});
        let addressChanged = this.editedDealer.addresses.find(a => a.id == id);
        Object.assign(addressChanged, addressChanges);

        // If the address changed and the dealer has 2 address, we switch the other address type.
        if (addressChanges.addressType != null) {
          
          let otherAddress = this.editedDealer.addresses.find(a => a.id != id);
          if (otherAddress) {
            otherAddress.addressType = addressChanges.addressType == 0 ? 1 : 0;

            // Update the DB change
            this.editedDealer.changes.addedOrModifiedAddresses.push({id: otherAddress.id, addressType: otherAddress.addressType});

            // Update the address type visual component
            let otherAddressIndex = index == 0 ? 1 : 0;
            this.$refs['dealerAddressTypeRef' + otherAddressIndex][0].updateAddressTypeForDealer(otherAddress.addressType);
          }
        }
      }
      this.saveChanges();
    },
    async confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.DeleteAddress:
          this.deleteAddress(this.selectedAddressIdx);
          break;
        case ConfirmAction.DeleteDealerMachine:
          {
            var scope = this;
            UserService.deleteDealerMachine(this.selectedDealerMachine)
            .then((response) => {
              scope.SET_ALL_DEALER_MACHINES(response.data);
              scope.setSelectedDealerMachine(scope.editedDealer.dealer_machines.length > 0 ? scope.editedDealer.dealer_machines[0].id : null);
              scope.$forceUpdate();
              scope.openToast({ message: scope.$t("dealer.dealer_machine_deleted"), duration: 5000, color: 'green'});
            })
            .catch((err) => {
              console.log(err)
              scope.openToast({ message: scope.$t("dealer.dealer_machine_in_use"), duration: 5000, color: 'red'});
            });
          }
          break;
        case ConfirmAction.DeleteDealerMachineMotor:
          {
            this.deleteDealerMachineMotor();
            this.$forceUpdate();
          }
          break;
        case ConfirmAction.DeleteCorrelationTable:
          this.deleteCorrelationTable();
          break;
        case ConfirmAction.DeleteMapKml:
          this.deleteDeleteMapKml();
          break;
        case ConfirmAction.SendCorrelationTableEmail:
          {
            this.isSendingCorrelationTableEmail = true;

            var formData = new FormData();
            formData.append("dealerId", this.editedDealer.id);
            formData.append("dealerMachineId", this.selectedDealerMachine.id);
            formData.append("dealerMachineMotorId", this.selectedDealerMachineMotor.id);
            
            let response = await UserService.sendCorrelationTableEmail(formData);
            console.log(response)

            if (response.success) {
              console.log(`Email sent`)
              this.openToast({ message: this.$t("dealer.email_sent") + '\n' + response.data.emails.replace(/;/g,"\n"), duration: 15000, color: 'green'});
            }
            else {
              this.openToast({ message: response.data, duration: 15000, color: 'red'});
            }
            this.isSendingCorrelationTableEmail = false;
            break;
          }
        default:
          break;
      }
      this.confirmDialog = false;
    },

    confirmDeleteAddress: function(addressIdx) {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t("confirm.head");
      this.confirmDialogBody = this.$t("dealers.delete_address");
      this.confirmAction = ConfirmAction.DeleteAddress;
      this.confirmYesNo = true;
      this.selectedAddressIdx = addressIdx;
    },

    deleteAddress(index) {
      let addressToRemove = this.editedDealer.addresses[index];
      this.editedDealer.addresses.splice(index, 1);

      if (addressToRemove.id > 0) {
        this.editedDealer.changes.deletedAddresses.push(addressToRemove.id);
      }
      this.saveChanges();
    },

    deleteDealerMachineMotor() {
      let motorId = this.selectedDealerMachineMotor.id;
      if (this.selectedDealerMachine) {
        let changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          deletedDealerMachineMotors: []
        };

        let motorToBeDeleted = this.selectedDealerMachine.dealer_machine_motors.find(x => x.id == motorId);
        if (motorId >= 0) {
          changes.deletedDealerMachineMotors.push(motorToBeDeleted);
          this.saveDealerMachine({ changes: changes });
        }
        else {
          for (let i = 0; i < this.selectedDealerMachine.editMotors.length; i++) {
            if (this.selectedDealerMachine.editMotors[i].id == motorId) {
              this.selectedDealerMachine.editMotors.splice(i, 1);
              this.setSelectedDealerMachineMotor(null);
              this.recalculateSimulation();
              break;
            }
          }
        }
      }
    },

    onDealerMachineNameChange() {
      let changes = {
        changes: {
          dealerMachineId: this.selectedDealerMachine.id,
          name: this.selectedDealerMachine.name,
        },
      };
      this.saveDealerMachine(changes);
    },
    onDealerMachineNumberChange() {
      let changes = {
        changes: {
          dealerMachineId: this.selectedDealerMachine.id,
          serialNumber: this.selectedDealerMachine.serialNumber,
        },
      };
      this.saveDealerMachine(changes);
    },
    onDealerMachineIsActiveChange() {
      let changes = {
        changes: {
          dealerMachineId: this.selectedDealerMachine.id,
          isActive: this.selectedDealerMachine.isActive,
        },
      };
      this.saveDealerMachine(changes);
    },
    onDealerMachineChange() { 
      let changes = {
        changes: {
          dealerMachineId: this.selectedDealerMachine.id,
          machineId: this.selectedDealerMachine.machineId,
        },
      };
      this.saveDealerMachine(changes);
    },
    updateLastCalibrationDate() {
      let today = new Date();
      let lastCalibrationDate = new Date(this.selectedDealerMachineMotor.lastCalibration);
      
      if (today > lastCalibrationDate) {
        let changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
          lastCalibration: this.selectedDealerMachineMotor.lastCalibration
        }
        this.lastCalibrationDateModal = false
        
        this.saveDealerMachineMotor({ changes: changes });
      }
      else {
        this.selectedDealerMachineMotor.lastCalibration = this.selectedDealerMachineMotor.initialLastCalibration;
        this.openToast({ message: this.$t("dealer.last_calibration_error"), duration: 15000, color: 'red'});
      }
    },
    onFactor1FormulaChanged() {
      if (this.selectedDealerMachineMotor.factor1) {
        this.selectedDealerMachineMotor.factor1 = this.selectedDealerMachineMotor.factor1.replaceAll(',','.');
      }
      this.selectedDealerMachineMotor.factor1 = parseFloat(this.selectedDealerMachineMotor.factor1);

      if (isNaN(this.selectedDealerMachineMotor.factor1)) {
        this.selectedDealerMachineMotor.factor1 = null;
      }
      let changes = {
        dealerMachineId: this.selectedDealerMachine.id,
        dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
        factor1: this.selectedDealerMachineMotor.factor1
      };
      this.saveDealerMachineMotor({ changes: changes });
      this.recalculateSimulation();
    },
    onFactor2FormulaChanged() {
      if (this.selectedDealerMachineMotor.factor2) {
        this.selectedDealerMachineMotor.factor2 = this.selectedDealerMachineMotor.factor2.replaceAll(',','.');
      }
      this.selectedDealerMachineMotor.factor2 = parseFloat(this.selectedDealerMachineMotor.factor2);

      if (isNaN(this.selectedDealerMachineMotor.factor2)) {
        this.selectedDealerMachineMotor.factor2 = null;
      }
      let changes = {
        dealerMachineId: this.selectedDealerMachine.id,
        dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
        factor2: this.selectedDealerMachineMotor.factor2
      };
      this.saveDealerMachineMotor({ changes: changes });
      this.recalculateSimulation();
    },
    onFactor3FormulaChanged() {
      if (this.selectedDealerMachineMotor.factor3) {
        this.selectedDealerMachineMotor.factor3 = this.selectedDealerMachineMotor.factor3.replaceAll(',','.');
      }
      this.selectedDealerMachineMotor.factor3 = parseFloat(this.selectedDealerMachineMotor.factor3);

      if (isNaN(this.selectedDealerMachineMotor.factor3)) {
        this.selectedDealerMachineMotor.factor3 = null;
      }
      let changes = {
        dealerMachineId: this.selectedDealerMachine.id,
        dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
        factor3: this.selectedDealerMachineMotor.factor3
      };
      this.saveDealerMachineMotor({ changes: changes });
      this.recalculateSimulation();
    },
    onDealerMachineMotorTorqueFormulaChanged() {
      if (this.selectedDealerMachineMotor.torqueFormula) {
        this.selectedDealerMachineMotor.torqueFormula = this.selectedDealerMachineMotor.torqueFormula.replaceAll(',','.');
      }
      let formula = translateFormula(this.selectedDealerMachineMotor.torqueFormula);

      var parser = new formulaParser();
      parser.setVariable("FACTOR_1", 100);
      parser.setVariable('FACTOR_2', 100);
      parser.setVariable('FACTOR_3', 100);
      parser.setVariable('DEPTH_M', 100);
      parser.setVariable('PRESSURE_PSI', 100);
      parser.setVariable('TORQUE_FORMULA', 100);

      let calculatedTorque = parser.parse(formula);
      if (calculatedTorque.error) {
        this.selectedDealerMachineMotor.torqueFormula = null;
        this.openToast({ message: "Formula error " + calculatedTorque.error, duration: 15000, color: 'red'});
      }

      if (this.selectedDealerMachineMotor.torqueFormula == '') {
        this.selectedDealerMachineMotor.torqueFormula = null;
      }

      let changes = {
        dealerMachineId: this.selectedDealerMachine.id,
        dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
        torqueFormula: this.selectedDealerMachineMotor.torqueFormula
      };
      // Reset the other dependant formulas
      if (!this.selectedDealerMachineMotor.torqueFormula) {
        changes.compressionFormula = null;
        this.selectedDealerMachineMotor.compressionFormula = null;
        changes.tensionFormula = null;
        this.selectedDealerMachineMotor.tensionFormula = null;
      }
      
      this.saveDealerMachineMotor({ changes: changes });

      this.recalculateSimulation();
    },
    onDealerMachineMotorCompressionFormulaChanged() {
      if (this.selectedDealerMachineMotor.compressionFormula) {
        this.selectedDealerMachineMotor.compressionFormula = this.selectedDealerMachineMotor.compressionFormula.replaceAll(',','.');
      }
      let formula = translateFormula(this.selectedDealerMachineMotor.compressionFormula);

      var parser = new formulaParser();
      parser.setVariable("FACTOR_1", 100);
      parser.setVariable('FACTOR_2', 100);
      parser.setVariable('FACTOR_3', 100);
      parser.setVariable('DEPTH_M', 100);
      parser.setVariable('PRESSURE_PSI', 100);
      parser.setVariable('TORQUE_LB_PI', 100);
      parser.setVariable('TORQUE_N_M', 100);
      parser.setVariable('COMPRESSION_FORMULA', 100);
      parser.setVariable('TORQUE_FORMULA', 100);
      parser.setVariable('KT', 100);

      let calculatedCompression = parser.parse(formula);
      if (calculatedCompression.error) {
        this.selectedDealerMachineMotor.compressionFormula = null;
        this.openToast({ message: "Formula error " + calculatedCompression.error, duration: 15000, color: 'red'});
      }

      if (this.selectedDealerMachineMotor.compressionFormula == '') {
        this.selectedDealerMachineMotor.compressionFormula = null;
      }
      let changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
          compressionFormula: this.selectedDealerMachineMotor.compressionFormula
      };
        // Reset the other dependant formulas
      if (!this.selectedDealerMachineMotor.compressionFormula) {
        changes.tensionFormula = null;
        this.selectedDealerMachineMotor.tensionFormula = null;
      }
      
      this.saveDealerMachineMotor({ changes: changes });

      this.recalculateSimulation();
    },
    onDealerMachineMotorTensionFormulaChanged() {
      if (this.selectedDealerMachineMotor.tensionFormula) {
        this.selectedDealerMachineMotor.tensionFormula = this.selectedDealerMachineMotor.tensionFormula.replaceAll(',','.');
      }
      let formula = translateFormula(this.selectedDealerMachineMotor.tensionFormula);

      var parser = new formulaParser();
      parser.setVariable("FACTOR_1", 100);
      parser.setVariable('FACTOR_2', 100);
      parser.setVariable('FACTOR_3', 100);
      parser.setVariable('DEPTH_M', 100);
      parser.setVariable('PRESSURE_PSI', 100);
      parser.setVariable('TORQUE_LB_PI', 100);
      parser.setVariable('TORQUE_N_M', 100);
      parser.setVariable('COMPRESSION_LB', 100);
      parser.setVariable('COMPRESSION_KN', 100);
      parser.setVariable('MAXIMUM_ALLOWABLE_TENSION_DEPTH_LB', 100);
      parser.setVariable('TORQUE_FORMULA', 100);
      parser.setVariable('COMPRESSION_FORMULA', 100);
      parser.setVariable('TENSION_FORMULA', 100);
      parser.setVariable('KT', 100);
      parser.setVariable('DIAM_M', 100);

      let calculatedTension = parser.parse(formula);
      if (calculatedTension.error) {
        this.selectedDealerMachineMotor.tensionFormula = null;
        this.openToast({ message: "Formula error " + calculatedTension.error, duration: 15000, color: 'red'});
      }

      if (this.selectedDealerMachineMotor.tensionFormula == '') {
        this.selectedDealerMachineMotor.tensionFormula = null;
      }
      let changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          dealerMachineMotorId: this.selectedDealerMachineMotor.motorId,
          tensionFormula: this.selectedDealerMachineMotor.tensionFormula
      };
      
      this.saveDealerMachineMotor({ changes: changes });
      
      this.recalculateSimulation();
    },
    async recalculateSimulation() {
      this.testTorque = null;
      this.testCompression = null;
      this.testTension = null;
      let compressionResult = null;

      if (!this.selectedDealerMachineMotor) {
        return;
      }

      let pileModel = this.engineering_department ? this.engineering_department.pile_model_engineering_departments.find(d => d.pileModelId == this.testPileModel.id) : null;
      let kt = pileModel ? pileModel.factorKT : 0;

      if (this.selectedDealerMachineMotor.torqueFormula) {
        
        let data = {
          engineeringDepartment: this.engineering_department, 
          formula: this.selectedDealerMachineMotor.torqueFormula, 
          pressure: this.testPressure, 
          maxTorque: pileModel.maxTorque,
          depth_m: this.testDepth, 
          factor1: this.selectedDealerMachineMotor.factor1, 
          factor2: this.selectedDealerMachineMotor.factor2, 
          factor3: this.selectedDealerMachineMotor.factor3
        };

        let response = await DealerService.calculateTorque(data);
        
        if (response && response.data && response.data.result != null) {
          this.testTorque = Math.round(response.data.result);
        }
      }

      if (this.selectedDealerMachineMotor.compressionFormula) {

        let maxCompression = pileModel ? pileModel.maxCompression : 0;

        let data = {
          engineeringDepartment: this.engineering_department, 
          formula: this.selectedDealerMachineMotor.compressionFormula, 
          pressure: this.testPressure, 
          depth_m: this.testDepth, 
          factor1: this.selectedDealerMachineMotor.factor1, 
          factor2: this.selectedDealerMachineMotor.factor2, 
          factor3: this.selectedDealerMachineMotor.factor3, 
          torque: this.testTorque, 
          safetyFactor: this.safetyFactorCompression, 
          maxCompression: maxCompression, 
          kt: kt
        }
        let response = await DealerService.calculateCompression(data);

        if (response && response.data && response.data.result) {
          compressionResult = response.data.result;
          if (this.engineering_department.roundCompTensionHundred) {
            this.testCompression = Math.round(compressionResult.factorResult / 100) * 100;
          }
          else {
            this.testCompression = Math.round(compressionResult.factorResult);
          }
        }
      }

      if (this.selectedDealerMachineMotor.tensionFormula && 
          this.engineering_department && this.engineering_department.capacityTensionCalculator) {

          let maxTension = pileModel ? pileModel.maxTension : 0;
          let diam_in = this.testPileHelix.name.replace(/\D/g,'');

          let data = {
            engineeringDepartment: this.engineering_department, 
            formula: this.selectedDealerMachineMotor.tensionFormula,
            pressure: this.testPressure, 
            depth_m: this.testDepth, 
            factor1: this.selectedDealerMachineMotor.factor1, 
            factor2: this.selectedDealerMachineMotor.factor2, 
            factor3: this.selectedDealerMachineMotor.factor3, 
            torque: this.testTorque, 
            compression: compressionResult ? compressionResult.rawResult : null, 
            safetyFactor: this.safetyFactorTension, 
            maxTension: maxTension, 
            kt: kt, 
            diam_in: diam_in
          }
          let response = await DealerService.calculateTension(data);

          if (response && response.data && response.data.result)  { 
            let tensionResult = response.data.result;
            if (this.engineering_department.roundCompTensionHundred) {
              this.testTension = Math.round(tensionResult.factorResult / 100) * 100;
            }
            else {
              this.testTension = Math.round(tensionResult.factorResult); 
            }
          }
        }
    },
    getLowestMachineMotorId(machine) {
      let lowestMachineMotorId = 0;
      for (let i = 0; i < machine.editMotors.length; i++) {
        if (machine.editMotors[i].id < lowestMachineMotorId) {
          lowestMachineMotorId = machine.editMotors[i].id;
        }
      }
      return lowestMachineMotorId;
    },
    addNewDealerMachineMotor() {
      let lowestId = this.getLowestMachineMotorId(this.selectedDealerMachine);
      
      // Do not allow to add more than one motor without an id. Id is set when selecting a motor.
      if (lowestId >= 0) {
        this.selectedDealerMachine.editMotors.push({ id: -1, motorId: -1, name: ''});
        this.setSelectedDealerMachineMotor(-1);
        this.recalculateSimulation();
      }
    },
    selectedMotorChanged() {
      let changes= null;
    
      let deletedMotor = null;
      let addedMotorId = this.selectedDealerMachineMotor.motorId.motorId;

      for (let i = 0; i < this.selectedDealerMachine.dealer_machine_motors.length; i++) {
        if (this.selectedDealerMachine.dealer_machine_motors[i].id == this.selectedDealerMachineMotor.id) {
          deletedMotor = this.selectedDealerMachine.dealer_machine_motors[i];
          deletedMotor.motorId = deletedMotor.motor.id;
        }
      }

      if (deletedMotor) {
        changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          addedDealerMachineMotors: [],
          deletedDealerMachineMotors: []
        };
        changes.deletedDealerMachineMotors.push(deletedMotor);
      }
      else {
        changes = {
          dealerMachineId: this.selectedDealerMachine.id,
          addedDealerMachineMotors: [],
        };
      }
      changes.addedDealerMachineMotors.push(addedMotorId);
      
      console.log(changes)

      this.saveDealerMachine({ changes: changes });
    },
    async addNewDealerMachine() {
      let response = await UserService.addDealerMachine({ dealerId: this.editedDealer.id })
      this.SET_ALL_DEALER_MACHINES(response.data);

      // Select the newly created machine
      if (this.editedDealer.dealer_machines.length > 0) {
        this.setSelectedDealerMachine(this.editedDealer.dealer_machines[this.editedDealer.dealer_machines.length-1].id);
      }
    },
    setEditedDealer(dealerId) {
      console.log('Selected: ' + this.selectedDealerId + ', Setting dealer: ' + dealerId);
      this.setSelectedDealerPageId(dealerId);
      this.retrieveDealerData();
    },
    retrieveDealerData() {
      console.log('Retrieving dealer data...')
      var scope = this;

      DealerService.getDealerPageData(this.selectedDealerId, getLanguage())
        .then((response) => {
          this.SET_EDITED_DEALER(response.data.dealer);
          //console.log('response.data', response.data)
          scope.dealerGroups = response.data.dealerGroups;
          scope.engineeringDepartments = response.data.engineeringDepartments;
          scope.machines = response.data.machines;
          scope.timeZones = response.data.timeZones;
          scope.motors = response.data.motors;
          scope.pileModels = response.data.pileModels;
          scope.helixModels = response.data.helixModels;

          scope.testPileModel = scope.pileModels.find(m => m.name == "P2");
          scope.testPileHelix = scope.helixModels.find(m => m.name == "8");
      })
      .catch(function (error) {
        // handle error
        let errorMsg = parseError(error);
        scope.showErrorMessage(errorMsg);

        if (error.toString().includes('401')) {
          scope.logout(this.$socket);
        }
        else {
          scope.$router.push('/');
        }
      })
    },
    setSelectedDealerPageId(id) {
      this.selectedDealerId = id;
      console.log('Setting selected dealer id to ' + id)
    },
    SET_EDITED_DEALER(dealer) {
      this.editedDealer = dealer;
      console.log('SET_EDITED_DEALER', dealer)

      formatDealerSupportingPlates(dealer);

      if (this.editedDealer) {
        this.editedDealer.dealer_machines.forEach((dealerMachine) => {
          if(dealerMachine.id != -1){
            if (!dealerMachine.dealer_machine_motors) {
              dealerMachine.dealer_machine_motors = [];
            }
            dealerMachine.editMotors = JSON.parse(JSON.stringify(dealerMachine.dealer_machine_motors, null, 2));
          }
        });
        this.resetDealerChanges();

        this.editedDealer.editSupportingPlates = this.editedDealer.dealerSupportingPlates.filter(sp => sp.selected);
        this.editedDealer.placeAutocompleteRestricted = this.editedDealer.placeAutocompleteRestricted?.split(',');
        
        this.editedDealer.showCustomerCategory = this.editedDealer.site_reports_displays.some(el => el.zone === "CUSTOMER_CATEGORY" && el.display !== "HIDDEN");
        this.editedDealer.showPileName = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_NAME" && el.display !== "HIDDEN");
        this.editedDealer.showPileLength = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_LENGTH" && el.display !== "HIDDEN");
        this.showPileLengthDefault = this.editedDealer.showPileLength;
        this.editedDealer.showPileFinish = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_FINISH" && el.display !== "HIDDEN");
        this.showPileFinishDefault = this.editedDealer.showPileFinish;
        this.editedDealer.showContacts = this.editedDealer.site_reports_displays.some(el => el.zone === "CONTACTS" && el.display !== "HIDDEN");
        this.editedDealer.showCustomerTelephone = this.editedDealer.site_reports_displays.some(el => el.zone === "CUSTOMER_PHONE" && el.display !== "HIDDEN");
        this.editedDealer.showPermit = this.editedDealer.site_reports_displays.some(el => el.zone === "PERMIT" && el.display !== "HIDDEN");
        this.editedDealer.showCustomerEmail = this.editedDealer.site_reports_displays.some(el => el.zone === "CUSTOMER_EMAIL" && el.display !== "HIDDEN");
        this.editedDealer.showHammer = this.editedDealer.site_reports_displays.some(el => el.zone === "HAMMER" && el.display !== "HIDDEN");
        this.showHammerDefault = this.editedDealer.showHammer;
        this.editedDealer.showInfoWelding = this.editedDealer.site_reports_displays.some(el => el.zone === "INFO_WELDING" && el.display !== "HIDDEN");
        this.editedDealer.showInfoSelfTappingScrew = this.editedDealer.site_reports_displays.some(el => el.zone === "INFO_SELF_TAPPING_SCREW" && el.display !== "HIDDEN");
        this.editedDealer.showInfoStickers = this.editedDealer.site_reports_displays.some(el => el.zone === "INFO_STICKERS" && el.display !== "HIDDEN");
        this.editedDealer.showInfoGreenSleeve = this.editedDealer.site_reports_displays.some(el => el.zone === "INFO_GREEN_SLEEVE" && el.display !== "HIDDEN");
        this.editedDealer.showFieldReportSummary = this.editedDealer.site_reports_displays.some(el => el.zone === "FIELD_REPORT_SUMMARY" && el.display !== "HIDDEN");
        this.editedDealer.showPileImpacts = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_IMPACTS" && el.display !== "HIDDEN");
        this.editedDealer.showPileNotInstalled = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_NOT_INSTALLED" && el.display !== "HIDDEN");
        this.editedDealer.showAnchoredBedrock = this.editedDealer.site_reports_displays.some(el => el.zone === "ANCHORED_BEDROCK" && el.display !== "HIDDEN");
        this.editedDealer.showPileConcreteBlock = this.editedDealer.site_reports_displays.some(el => el.zone === "PILE_CONCRETE_BLOCK" && el.display !== "HIDDEN");
        this.editedDealer.showProjectReferenceNumber = this.editedDealer.site_reports_displays.some(el => el.zone === "PROJECT_REFERENCE_NUMBER" && el.display !== "HIDDEN");
        this.editedDealer.showSoilType = this.editedDealer.site_reports_displays.some(el => el.zone === "SOIL_TYPE" && el.display !== "HIDDEN");
        this.editedDealer.showProjectDimensions = this.editedDealer.site_reports_displays.some(el => el.zone === "PROJECT_DIMENSIONS" && el.display !== "HIDDEN");
        this.editedDealer.showProjectTypeHeight = this.editedDealer.site_reports_displays.some(el => el.zone === "PROJECT_HEIGHT" && el.display !== "HIDDEN");
        this.editedDealer.showComments = this.editedDealer.site_reports_displays.some(el => el.zone === "COMMENTS" && el.display !== "HIDDEN");
        this.editedDealer.showPdfComments = this.editedDealer.site_reports_displays.some(el => el.zone === "COMMENTS_PDF" && el.display !== "HIDDEN");
        this.editedDealer.showTorqueReadings = this.editedDealer.site_reports_displays.some(el => el.zone === "TORQUE_READINGS" && el.display !== "HIDDEN");
        this.editedDealer.showInvoiceLength = this.editedDealer.site_reports_displays.some(el => el.zone === "INVOICE_LENGTH" && el.display !== "HIDDEN");
        this.editedDealer.showExtension = this.editedDealer.site_reports_displays.some(el => el.zone === "EXTENSION" && el.display !== "HIDDEN");
        this.showExtensionDefault = this.editedDealer.showExtension;
        this.editedDealer.showPdfTorque = this.editedDealer.site_reports_displays.some(el => el.zone === "PDF_TORQUE" && el.display !== "HIDDEN");
        this.editedDealer.showPdfCompression = this.editedDealer.site_reports_displays.some(el => el.zone === "PDF_COMPRESSION" && el.display !== "HIDDEN");
        this.editedDealer.showPdfTension = this.editedDealer.site_reports_displays.some(el => el.zone === "PDF_TENSION" && el.display !== "HIDDEN");
        this.editedDealer.showTorque = this.editedDealer.site_reports_displays.some(el => el.zone === "TORQUE" && el.display !== "HIDDEN");
        this.editedDealer.showCompression = this.editedDealer.site_reports_displays.some(el => el.zone === "COMPRESSION" && el.display !== "HIDDEN");
        this.editedDealer.showTension = this.editedDealer.site_reports_displays.some(el => el.zone === "TENSION" && el.display !== "HIDDEN");
        this.editedDealer.showInstallers = this.editedDealer.site_reports_displays.some(el => el.zone === "INSTALLERS" && el.display !== "HIDDEN");
        this.editedDealer.showWelders = this.editedDealer.site_reports_displays.some(el => el.zone === "WELDERS" && el.display !== "HIDDEN");
        this.editedDealer.showCollaborators = this.editedDealer.site_reports_displays.some(el => el.zone === "COLLABORATORS" && el.display !== "HIDDEN");
        this.editedDealer.showPdfSoilType = this.editedDealer.site_reports_displays.some(el => el.zone === "SOIL_TYPE_PDF" && el.display !== "HIDDEN");
      }

      console.log('EDITED DEALER ', this.editedDealer)
    },
    async saveChanges() {
      console.log("saveChanges ", this.editedDealer.changes);

      let response = await UserService.saveDealerChanges(this.editedDealer);
      console.log(response);
      if (response.status == 200) {
        for (let i = 0; i < response.data.addedAddresses.length; i++) {
          for (let j = 0; j < this.editedDealer.addresses.length; j++) {
            if (
              this.editedDealer.addresses[j].id ==
              response.data.addedAddresses[i].oldId
            ) {
              this.editedDealer.addresses[j].id =
                response.data.addedAddresses[i].newId;
            }
          }
        }
        // Reset changes
        this.resetDealerChanges();
      } else {
        this.showErrorMessage(parseError(response));
      }
    },
    resetDealerChanges() {
      if (!this.editedDealer) {
      return; 
      }
      this.editedDealer.changes = {
        addedOrModifiedAddresses: [],
        deletedAddresses: [],
      };
      this.editedDealer.addresses.forEach((address) => {
          if (address.countryId) {
            address.country = this.countries.find(
              (c) => c.id == address.countryId
            );
          }
      });
    },
    saveDealerMachine(data) {
      var scope = this;
      UserService.saveDealerMachine(data, getLanguage())
        .then((response) => {
          scope.SET_ALL_DEALER_MACHINES(response.data);
        })
        .catch((err) => {
          let originMachineId = err.response.data.id ? err.response.data.id : null;
          if (originMachineId) {
            scope.resetDealerMachineMachineId(originMachineId);
          }
          // Readd the motor that was not deleted to the machine editMotors
          scope.setSelectedDealerMachineMotor(null);
          scope.openToast({ message: parseError(err), duration: 5000, color: 'red'});
        });
    },
    saveDealerMachineMotor(data) {
      var scope = this;
      UserService.saveDealerMachine(data, getLanguage())
        .catch((err) => {
          scope.openToast({ message: parseError(err), duration: 5000, color: 'red'});
        });
    },
    SET_ALL_DEALER_MACHINES({ dealerMachines, selectedId }) {
      console.log('editedDealer SET_ALL_DEALER_MACHINES', this.editedDealer, dealerMachines, selectedId)
      if (this.editedDealer) {
        this.editedDealer.dealer_machines = dealerMachines;

        // Refaire le lien vers le selectedDealerMachine pour assurer le fonctionnement des bindings
        if (this.selectedDealerMachine) {
          this.selectedDealerMachine = this.editedDealer.dealer_machines.find(x => x.id == this.selectedDealerMachine.id);
        }

        this.editedDealer.dealer_machines.forEach((dealerMachine) => {
          if (!dealerMachine.dealer_machine_motors) {
            dealerMachine.dealer_machine_motors = [];
          }
          dealerMachine.editMotors = JSON.parse(JSON.stringify(dealerMachine.dealer_machine_motors, null, 2));
          for (let i = 0; i < dealerMachine.editMotors.length; i++) {
            dealerMachine.editMotors[i].name = dealerMachine.editMotors[i].motor.name;
          }
        });
        sortByProperties(this.editedDealer.dealer_machines, ['name', 'serialNumber'])
        this.setSelectedMachineMotor(selectedId);
      }
    },
    setSelectedDealerMachine(id) {
      if (this.editedDealer) {
        this.selectedDealerMachine = this.editedDealer.dealer_machines.find(x => x.id == id);
      }
      else {
        this.selectedDealerMachine = null;
      }
      console.log('setSelectedDealerMachine', this.selectedDealerMachine)
      this.setSelectedMachineMotor(null);
    },
    resetDealerMachineMachineId(originMachineId) {
      this.selectedDealerMachine.machineId = originMachineId;
    },
    setSelectedDealerMachineMotor(id) {
      this.setSelectedMachineMotor(id);
      console.log('setSelectedDealerMachineMotor', this.selectedDealerMachineMotor)
    },
    setSelectedMachineMotor(id) {
      if (this.selectedDealerMachine && id != null) {
        this.selectedDealerMachineMotor = this.selectedDealerMachine.dealer_machine_motors.find(x => x.id == id);
        if (id < 0) {
          //add new machine motor
          let tempMotor = { id: id, motorId: -1 }
          this.selectedDealerMachineMotor = tempMotor;
        }
        this.machineAvailableMotors = [];
        for (let i = 0; i < this.motors.length; i++) {
          if (!this.selectedDealerMachine.dealer_machine_motors.find(x => x.motorId == this.motors[i].motorId)) {
            this.machineAvailableMotors.push(this.motors[i]);
          }
        }
        if (this.selectedDealerMachineMotor.id > 0) {
          let motorId = this.selectedDealerMachineMotor.motorId;
          let selectedMotor = this.motors.find(m => m.motorId == motorId);
          this.machineAvailableMotors.push(selectedMotor);
        }

        this.selectedDealerMachineMotor.initialLastCalibration = this.selectedDealerMachineMotor.lastCalibration;
      }
      else {
        this.selectedDealerMachineMotor = null;
      }
    },
    addStandardSupportingPlate(plate) {
      this.editedDealer.standard_supporting_plates.push({
        dealer_standard_supporting_plates:  {
          dealerId: this.editedDealer.id,
          standardSupportingPlateId: plate.id
        },
        form: plate.form,
        id: plate.id,
        mobility: plate.mobility,
        name: plate.name
      });
    },
    removeStandardSupportingPlate(plate) {
      let plateIndex = this.editedDealer.standard_supporting_plates.findIndex(p => p.id == plate.id);
      if (plateIndex >= 0) {
        this.editedDealer.standard_supporting_plates.splice(plateIndex, 1);
      }
    },
    async handleMapUpload() {
      await this.handleMapFileUpload(this.$refs.mapKmlPath.files[0]);
      this.$refs.mapKmlPath.value = '';
    },
    async handleMapFileUpload(file) {
      this.isSavingMapKml = true;
      let resp = await FileService.addNewFile(file, 'dealer_kml', this.editedDealer.id);
      console.log('add resp:', resp);
      if (resp.data?.alreadyExists === true) this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000}); // The upload button is only visible if there is no file yet, but may happen with a drag/drop
      else if (!resp.success) this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      else {
        if (this.editedDealer.files == null) this.editedDealer.files = {};
        this.editedDealer.files.kml = resp.data;
      }
      this.isSavingMapKml = false;
    },
    dropMapKml(e) {
      let files = Array.from(e.dataTransfer.files);
      if (e && files && files[0].name.endsWith('.kml')) {
        this.handleMapFileUpload(files[0]);
      }
      this.dropped = 0;
    },
    confirmMapKmlDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteMapKml;
      this.confirmYesNo = true;
    },
    async deleteDeleteMapKml() {
      let resp = await FileService.deleteFile(this.editedDealer.files.kml);
      if (resp.success) this.editedDealer.files.kml = null;
      else this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      this.$forceUpdate();
    },
    selectFileName(file) {
      let el = document.querySelector('#' + file.inputId);
      if (!el) return setTimeout(() => this.selectFileName(file), 100);
      el.focus();
      el.select();
    },
    async renameFile(file, action) {
      if (action == 'enable') {
        if (!isNaN(this.isRenamingAFile?.id) && this.isRenamingAFile.id != file.id) this.renameFile(this.isRenamingAFile, 'disable');
        file.renaming = true;
        this.isRenamingAFile = file;
        file.inputId = 'ren' + Date.now();
        setTimeout(() => this.selectFileName(file), 100);
      }
      else { //disable
        this.isRenamingAFile = false;
        file.renaming = false;
        delete file.renaming;
        delete file.inputId;
        FileService.renameFile(file);
      }
      this.$forceUpdate();
    },
    async downloadFile(file) {
      if (file.renaming) return;
      if (file.extension == 'pdf') {
        this.viewPdf.show = true;
        this.viewPdf.title = file.filename;
        try {
          let result = await axios.get(file.url.download, { headers: authHeader(), responseType: 'blob' });
          this.viewPdf.data = await result.data.arrayBuffer();
        } catch (error) {
          console.log(error);
          this.viewPdf.show = false;
          this.openToast({ message: error, duration: 5000, color: 'red'});
        }
      }
      else {
        window.open(file.url.download, '_blank');
      }
    },
  },
};
</script>

<style scoped>

label {
  color: rgba(0, 0, 0, 0.6);
}

.alternate-odd {
  background: #e8e4e47a;
}

.alternate-even {
  background: #fcf7f77a;
}

.address-block {
  position: relative;
  padding: 5px;
  margin: 5px 0px;

  border-radius: 6px;
}

.delete-address-btn {
  position: absolute;
  top: 3px;
  right: 0px;
  margin: 16px;
}

.white-text {
  color: white;
}

.inlineblock {
  display: inline-block;
}
.active-dealer-machine {
  border-radius: 8px;
  background: rgba(0, 159, 77, 0.1);
  color: #009f4d;
}
.requests-anchor {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: inline-block;
  height: 48px;
}
.requests-anchor ion-thumbnail {
  display: inline-block;
  height: 100%;
}
.request-container {
  position: relative;
}
.request-container + .request-container {
  margin-top: 5px;
}
.wrappedfile {
  text-overflow: ellipsis !important;
  max-width: 49%;
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
}
.required-img{
  width:48px;
  height:48px;
  border:1px dashed black; 
  border-radius:8px;
}
.upload-btn-wrapper {
  float: right;
}

.upload-btn-wrapper button {
  box-shadow: none;
  height: 50px;
  width: 50px;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.delete-photo-icon{
  position:absolute;
  top:10px;
  right:0px;
}
.rename-file-icon{
  position:absolute;
  top:10px;
  right:30px;
}
.default-motor {
  color: #009f4d;
    position: absolute;
    right: 100px;
    font-size: 18px;
}

#comm-info .v-input + .v-input {
  margin-top: 15px;
}

.editingFile {
  display: inline-block;
  padding-left: 10px;
  position: absolute;
}
</style>
