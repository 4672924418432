const INCHES_MM_CONSTANT = 25.4
const FEETS_METERS_CONSTANT = 0.3048001 // M.T Added a small derivation ..001 to get flush numbers, 2m in report was giving 1m 100cm 
const FEETS_CENTIMETERS_CONSTANT = 30.48001

/* ---- INCHES TO MILLIMETERS  --- */
const convertInchesToMM = (inches) => {
    return inches == null ? undefined : Math.round(inches * INCHES_MM_CONSTANT);
};

const convertMMtoInches = (valueInMM) => {
    return valueInMM / INCHES_MM_CONSTANT;
};
/* ---- INCHES TO FRACTIONS  --- */

const convertToInchesInt = (valueInInches) => {
    return valueInInches == null ? null : Math.trunc(parseFloat(valueInInches))
};

const convertToPrettyInches = (inches) => {
    var num = convertToInchesInt(inches)
    return `${num == null ? "" : num} ${convertToFraction(inches, true)}`;
};

const convertToFraction = (valueInInches, pretty = false) => {
    var val = parseFloat(valueInInches - Math.trunc(valueInInches))
    let fractionsCopy = [...FRACTIONS];

    var closest = 0

    var foundFraction = FRACTIONS.find((x) => x.value == val);

    if(!foundFraction){
        fractionsCopy.sort((a, b) => {
            return Math.abs(val - a.value) - Math.abs(val - b.value);
        })

        closest = pretty ? fractionsCopy[0].text : fractionsCopy[0].value
    }
    else{
      closest = pretty ? foundFraction.text: foundFraction.value
    }
    
    if (valueInInches != null && closest == "") {
        closest = 0;
    }

    return closest;
};

const convertFeetsToMeters = (feets, fixedDecimals = true) => {
    let res = parseFloat(feets * FEETS_METERS_CONSTANT);
    if (fixedDecimals) {
        return res.toFixed(2)
    }
    return res;
};

const convertFeetsToFeetAndInches = (feets) => {
    return `${Math.trunc(feets)}' ${Math.round((feets - Math.trunc(feets)) * 12)}"`
};

const convertMetersToFeets = (meters) => {
    return (meters * 3.28084).toFixed(2);
};

/* FEETS */

const feetsToMetersIntOnly = (value) => {
    return value == null ? undefined : Math.trunc(value * FEETS_METERS_CONSTANT)
};

const feetsToOnlyCentimeters = (value) => {
    return value == null ? undefined : Math.round(value * FEETS_CENTIMETERS_CONSTANT) - feetsToMetersIntOnly(value) * 100
};

const onlyFeets = (value) => {
    return value == null ? undefined : Math.trunc(value)
};
  
const onlyInches = (value) => {
    return value == null ? undefined : Math.round((value - Math.trunc(value)) * 12)
};

const FRACTIONS = [
    { text: "", value: "" },
    { text: '0', value: 0 },
    { text: '1/16"', value: 0.0625 },
    { text: '1/8"', value: 0.125 },
    { text: '3/16"', value: 0.1875 },
    { text: '1/4"', value: 0.25 },
    { text: '5/16"', value: 0.3125 },
    { text: '3/8"', value: 0.375 },
    { text: '7/16"', value: 0.4375 },
    { text: '1/2"', value: 0.5 },
    { text: '9/16"', value: 0.5625 },
    { text: '5/8"', value: 0.625 },
    { text: '11/16"', value: 0.6875 },
    { text: '3/4"', value: 0.75 },
    { text: '13/16"', value: 0.8125 },
    { text: '7/8"', value: 0.875 },
    { text: '15/16"', value: 0.9375 },
]

export {
    // Millimeters and Inches
    convertMMtoInches,
    convertInchesToMM,
    convertToInchesInt,
    convertToPrettyInches,
    convertToFraction,
    // Feets & Meters
    convertFeetsToMeters,
    convertFeetsToFeetAndInches,
    convertMetersToFeets,
    feetsToMetersIntOnly,
    feetsToOnlyCentimeters,
    onlyFeets,
    onlyInches,
    // Inches fractions
    FRACTIONS
};